import React from "react"

const CirclesImage = props => (
  <svg width={62.08} height={134.3} viewBox="0 0 62.08 134.3" {...props}>
    <g transform="translate(-1139.014 -133.005)">
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 133.005)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#ff5252"
        transform="translate(1153.139 133.005)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 133.005)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 133.005)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 133.005)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 147.307)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 147.307)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 147.307)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 147.307)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 147.307)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 161.609)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 161.609)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 161.609)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 161.609)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 161.609)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 175.911)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 175.911)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 175.911)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 175.911)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 175.911)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 190.213)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 190.213)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 190.213)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 190.213)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 190.213)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 204.515)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 204.515)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 204.515)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 204.515)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 204.515)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 218.817)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 218.817)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 218.817)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 218.817)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 218.817)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 233.12)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 233.12)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 233.12)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 233.12)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 233.12)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 247.422)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 247.422)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 247.422)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 247.422)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 247.422)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1139.014 261.724)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1153.139 261.724)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1167.264 261.724)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1181.388 261.724)"
      />
      <circle
        cx={2.79}
        cy={2.79}
        r={2.79}
        fill="#e6e6e6"
        transform="translate(1195.513 261.724)"
      />
    </g>
  </svg>
)

export default CirclesImage
