import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import Icon from "../atoms/icon"
import { device } from "../ions/breakpoints"
import logo from "../../images/marketing-logo.svg"

const Wrapper = styled.header`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  font-size: 0.85rem;
  font-weight: var(--extraBold);
  text-transform: uppercase;

  img {
    width: 150px;
    height: auto;
  }

  ul {
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0;

    li {
      list-style: none;
      width: 30px;
      height: 30px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--default);
        text-decoration: none;
        transition-duration: 0.3s;

        svg {
          width: auto;
          height: 24px;
          fill: var(--default);
          transition-duration: 0.3s;
        }

        &:hover {
          color: var(--red);

          svg {
            fill: var(--red);
          }
        }
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  @media ${device.s} {
    padding: 0 30px;
  }

  @media ${device.m} {
    padding: 0 50px;
  }
`

const Header = ({ siteTitle, i18n, linkedin, twitter, facebook }) => {
  return (
    <Wrapper>
      <div>
        <Link to="/">
          <img src={logo} alt={siteTitle} />
        </Link>
      </div>
      <div>
        <ul>
          {i18n && <li>{i18n}</li>}
          <li>
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <Icon icon="linkedin" />
            </a>
          </li>
          {twitter && (
            <li>
              <a
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <Icon icon="twitter" />
              </a>
            </li>
          )}
          {facebook && (
            <li>
              <a
                href={facebook}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <Icon icon="facebook" />
              </a>
            </li>
          )}
        </ul>
      </div>
    </Wrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  i18n: PropTypes.node,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "market[ing]",
}

export default Header
