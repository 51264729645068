import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ButtonLink from "../atoms/button-link"
import HeroImage from "../ions/hero-image"
import { device } from "../ions/breakpoints"

const Wrapper = styled.div`
  padding: 0 15px;

  @media ${device.s} {
    padding: 0 30px;
  }

  @media ${device.m} {
    padding: 0 50px;
  }
`

const Container = styled.div`
  margin: 0 auto;
  max-width: var(--maxWidthContent);
  display: flex;
  padding: 50px 0;

  > div {
    flex: 1;
  }

  a {
    margin-top: 50px;
  }

  @media ${device.m} {
    padding: 100px 0;
  }
`

const Intro = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.m} {
    text-align: left;
  }
`

const Image = styled.div`
  display: none;

  svg {
    width: 100%;
    height: auto;
    max-height: 450px;

    circle,
    path.area {
      transition-duration: 0.3s;

      &:hover {
        fill: var(--red);
      }
    }
  }

  @media ${device.m} {
    display: inherit;
  }
`

const Hero = ({ title, subtitle, buttonUrl, button }) => (
  <Wrapper>
    <Container>
      <Intro>
        <div>
          <h1>{title}</h1>
          <p>{subtitle}</p>
          <ButtonLink url={buttonUrl} value={button} />
        </div>
      </Intro>
      <Image>
        <HeroImage />
      </Image>
    </Container>
  </Wrapper>
)

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
}

export default Hero
