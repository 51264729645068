import React from "react"

const HeroImage = props => (
  <svg width={308.059} height={300} viewBox="0 0 308.059 300" {...props}>
    <g transform="translate(-1040 -140.005)">
      <g transform="translate(1133.366 140.005)">
        <circle cx={2.79} cy={2.79} r={2.79} fill="#e6e6e6" />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#ff5252"
          transform="translate(42.374 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 14.302)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 28.604)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#ff5252"
          transform="translate(42.374 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 42.906)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#ff5252"
          transform="translate(14.125 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 57.208)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 71.51)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#ff5252"
          transform="translate(28.249 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 85.812)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 100.113)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 114.415)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(0 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(14.125 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(28.249 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(42.374 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(56.499 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(70.623 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(84.748 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(98.872 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(112.997 128.717)"
        />
        <circle
          cx={2.79}
          cy={2.79}
          r={2.79}
          fill="#e6e6e6"
          transform="translate(127.122 128.717)"
        />
      </g>
      <g transform="translate(1040 179.611)">
        <g transform="translate(48.014 45.227)">
          <path
            fill="#e6e6e6"
            d="M0 0H251.254V160.914H0z"
            transform="translate(.107 27.096)"
          />
          <path
            fill="#fff"
            d="M0 0H236.885V131.212H0z"
            transform="translate(7.292 47.279)"
          />
          <path
            fill="#54e8ed"
            d="M0 0H251.254V10.674H0z"
            transform="translate(0 26.953)"
          />
          <circle
            cx={1.978}
            cy={1.978}
            r={1.978}
            fill="#fff"
            transform="translate(5.954 30.432)"
          />
          <circle
            cx={1.978}
            cy={1.978}
            r={1.978}
            fill="#fff"
            transform="translate(13.463 30.432)"
          />
          <circle
            cx={1.978}
            cy={1.978}
            r={1.978}
            fill="#fff"
            transform="translate(20.973 30.432)"
          />
          <path
            className="area"
            fill="#e6e6e6"
            d="M0 0H60.868V103.983H0z"
            transform="translate(28.816 60.893)"
          />
          <path
            fill="#54e8ed"
            d="M0 0H50.724V28.623H0z"
            transform="translate(100.191 60.893)"
          />
          <path
            className="area"
            fill="#e6e6e6"
            d="M0 0H50.724V30.796H0z"
            transform="translate(100.191 97.486)"
          />
          <path
            className="area"
            fill="#e6e6e6"
            d="M0 0H50.724V28.623H0z"
            transform="translate(100.191 136.253)"
          />
          <path
            className="area"
            fill="#e6e6e6"
            d="M0 0H60.868V45.315H0z"
            transform="translate(161.784 60.893)"
          />
          <path
            fill="#54e8ed"
            d="M0 0H60.868V45.315H0z"
            transform="translate(161.784 119.561)"
          />
          <path
            fill="#263238"
            d="M543.45 86.5l-.794-.211 17.338-65.229h100.577v.822h-99.945z"
            transform="translate(-417.374 -12.668)"
          />
          <path
            fill="#263238"
            d="M1008.305 141.1a8.531 8.531 0 118.531-8.531 8.531 8.531 0 01-8.531 8.531zm0-16.249a7.718 7.718 0 107.718 7.718 7.718 7.718 0 00-7.718-7.718z"
            transform="translate(-756.791 -124.034)"
          />
          <path
            fill="#263238"
            d="M515.785 428.45H415.208v-.822h99.946l17.176-64.618.794.211z"
            transform="translate(-339.661 -221.179)"
          />
          <path
            fill="#263238"
            d="M536.567 648.636a8.531 8.531 0 118.531-8.531 8.531 8.531 0 01-8.531 8.531zm0-16.249a7.718 7.718 0 107.718 7.718 7.718 7.718 0 00-7.718-7.718z"
            transform="translate(-469.414 -433.469)"
          />
          <circle
            cx={4.739}
            cy={4.739}
            r={4.739}
            fill="#ff5252"
            transform="translate(246.775 3.791)"
          />
          <circle
            cx={4.739}
            cy={4.739}
            r={4.739}
            fill="#ff5252"
            transform="translate(62.413 201.897)"
          />
        </g>
        <g id="undraw_file_analysis_8k9b">
          <path
            id="Path_17"
            fill="#e6e6e6"
            d="M453.208 193.831a3.361 3.361 0 013.361 3.361v134.457a3.361 3.361 0 01-3.361 3.361h-96.473a3.361 3.361 0 01-3.361-3.361V197.192a3.361 3.361 0 013.361-3.361h96.473"
            transform="translate(-353.374 -182.033)"
          />
          <path
            id="Path_18"
            fill="#fff"
            d="M362.991 337.122h95.69V203.447h-95.69z"
            transform="translate(-359.238 -187.897)"
          />
          <path
            className="area"
            id="Path_19"
            fill="#e6e6e6"
            d="M469.608 257.643a.672.672 0 01.672.672v1.345a.672.672 0 01-.672.672H416.5a.672.672 0 01-.672-.672v-1.345a.672.672 0 01.672-.672h53.111"
            transform="translate(-391.455 -220.943)"
          />
          <path
            className="area"
            id="Path_20"
            fill="#e6e6e6"
            d="M469.608 274.009a.672.672 0 01.672.672v1.345a.672.672 0 01-.672.672H416.5a.672.672 0 01-.672-.672v-1.345a.672.672 0 01.672-.672h53.111"
            transform="translate(-391.455 -230.923)"
          />
          <path
            id="Path_21"
            fill="#54e8ed"
            d="M469.608 290.376a.672.672 0 01.672.672v1.345a.672.672 0 01-.672.672H416.5a.672.672 0 01-.672-.672v-1.345a.672.672 0 01.672-.672h53.111"
            transform="translate(-391.455 -240.903)"
          />
          <path
            className="area"
            id="Path_22"
            fill="#e6e6e6"
            d="M469.608 307.1a.672.672 0 01.672.672v1.345a.672.672 0 01-.672.672H416.5a.672.672 0 01-.672-.672v-1.345a.672.672 0 01.672-.672h53.111"
            transform="translate(-391.455 -251.101)"
          />
          <path
            className="area"
            id="Path_23"
            fill="#e6e6e6"
            d="M469.608 323.467a.672.672 0 01.672.672v1.345a.672.672 0 01-.672.672H416.5a.672.672 0 01-.672-.672v-1.344a.672.672 0 01.672-.672h53.111"
            transform="translate(-391.455 -261.081)"
          />
          <path
            className="area"
            id="Path_24"
            fill="#e6e6e6"
            d="M469.608 339.834a.672.672 0 01.672.672v1.345a.672.672 0 01-.672.672H416.5a.672.672 0 01-.672-.672v-1.345a.672.672 0 01.672-.672h53.111"
            transform="translate(-391.455 -271.061)"
          />
          <path
            id="Path_25"
            fill="#54e8ed"
            d="M417.2 394.89a.781.781 0 00-.78.78v36.258a.781.781 0 00.78.78h52.34a.781.781 0 00.78-.78V395.67a.781.781 0 00-.78-.78z"
            transform="translate(-391.816 -304.633)"
          />
          <path
            id="Path_26"
            fill="#263238"
            d="M276.382 90.472l-.549-.146 11.991-45.105h69.549v.569H288.26z"
            transform="translate(-224.61 -39.538)"
          />
          <path
            id="Path_27"
            fill="#263238"
            d="M698.829 175.531a5.967 5.967 0 115.967-5.967 5.967 5.967 0 01-5.967 5.967zm0-11.366a5.4 5.4 0 105.4 5.4 5.4 5.4 0 00-5.4-5.4z"
            transform="translate(-560.383 -163.597)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default HeroImage
