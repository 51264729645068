export const sentences = [
  {
    en: "Rely on the virgin and don’t run",
    pt: "Fia-te na virgem e não corras",
  },
  {
    en: "I have to pull the ember to my sardine",
    pt: "Tenho de puxar a brasa à minha sardinha",
  },
  {
    en: "This is no bug of seven heads",
    pt: "Isto não é nenhum bicho de sete cabeças",
  },

  {
    en: "Barking up the wrong tree",
    pt: "Ladrar à árvore errada",
  },

  {
    en: "Chewing off more than you can chew",
    pt: "Morder mais do que consegues mastigar",
  },

  {
    en: "Hit the nail on the head",
    pt: "Bater na cabeça do prego",
  },

  {
    en: "Spill the beans",
    pt: "Espalha os feijões",
  },

  {
    en: "Sit tight",
    pt: "Senta-te apertado",
  },

  {
    en: "Cold turkey",
    pt: "Peru frio",
  },

  {
    en: "Sticky fingers",
    pt: "Dedos pegajosos",
  },

  {
    en: "Butter someone up",
    pt: "Esfregar alguém com manteiga",
  },

  {
    en: "Like putting lipstick on a pig",
    pt: "É como pôr batom num porco",
  },

  {
    en: "Prick her with a fork. She's done!",
    pt: "Pica-a com um garfo. Está pronta!",
  },

  {
    en: "Bite the bullet",
    pt: "Morder a bala",
  },

  {
    en: "By the skin of my teeth",
    pt: "Pela pele dos meus dentes",
  },

  {
    en: "Keep your eyes peeled",
    pt: "Mantém os olhos descascados",
  },

  {
    en: "Best thing since sliced bread",
    pt: "A melhor coisa desde pão fatiado",
  },

  {
    en: "You're twisting my arm",
    pt: "Estás a torcer o meu braço",
  },

  {
    en: "Don't get your knickers in a knot",
    pt: "Não fiques com as cuecas cheias de nós",
  },
]
