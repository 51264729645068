import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ServiceItem from "../molecules/service-item"
import { device } from "../ions/breakpoints"

const Wrapper = styled.div`
  background: linear-gradient(var(--white) 50%, var(--grey) 50%);
  padding: 0 30px 50px 30px;
  z-index: 2;

  @media ${device.m} {
    padding: 0 50px 50px 50px;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 30px;
  background-color: var(--white);
  max-width: var(--maxWidthContent);
  padding: 30px;
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.1);

  > div:not(:last-child) {
    margin-bottom: 30px;
  }

  @media ${device.m} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    padding: 100px;

    > div:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const OurWork = ({ services }) => (
  <Wrapper>
    <Container>
      {services.map((service, index) => (
        <ServiceItem
          key={index}
          icon={service.icon}
          title={service.title}
          text={service.text}
        />
      ))}
    </Container>
  </Wrapper>
)

OurWork.propTypes = {
  services: PropTypes.array.isRequired,
}

OurWork.defaultProps = {
  services: [],
}

export default OurWork
