import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Link = styled.a`
  display: inline-block;
  border-radius: 999px;
  background-color: var(--red);
  padding: 10px 20px;
  font-weight: var(--extraBold);
  color: var(--white);
  text-decoration: none;
  text-transform: capitalize;
  transition-duration: 0.3s;

  &:hover {
    background-color: hsl(0, 100%, 56%);
  }
`

const ButtonLink = ({ url, value }) => <Link href={url}>{value}</Link>

ButtonLink.propTypes = {
  url: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default ButtonLink
