import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Icon from "../atoms/icon"

const Item = styled.div`
  margin: 0 auto;
  max-width: 500px;
  text-align: center;

  > div {
    margin: 0 auto 30px auto;
    border-radius: 999px;
    background-color: var(--red);
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: auto;
      height: 50px;
    }

    &:last-child {
      svg {
        margin-left: 6px;
      }
    }
  }

  h3 {
    margin-bottom: 4px;
  }
`

const ServiceItem = ({ icon, title, text }) => (
  <Item>
    <div>
      <Icon icon={icon} fill="var(--white)" />
    </div>
    <h3>{title}</h3>
    <p>{text}</p>
  </Item>
)

ServiceItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default ServiceItem
