import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Icon from "../atoms/icon"
import { device } from "../ions/breakpoints"

const Wrapper = styled.footer`
  position: relative;
  background-color: var(--default);
  height: 80px;
  padding: 0 15px;
  font-size: 0.85rem;
  color: var(--white);
  z-index: 1;

  > div {
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: var(--maxWidthContent);

    ul {
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0;

      li {
        list-style: none;
        width: 30px;
        height: 30px;

        a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--default);
          text-decoration: none;
          transition-duration: 0.3s;

          svg {
            width: auto;
            height: 24px;
            fill: var(--white);
            transition-duration: 0.3s;
          }

          &:hover {
            color: var(--red);

            svg {
              fill: var(--red);
            }
          }
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  @media ${device.s} {
    padding: 0 30px;

    > div {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @media ${device.m} {
    margin-top: -80px;
    background: linear-gradient(to right, var(--default) 75%, transparent 75%);

    span {
      position: absolute;
      right: calc(25% + 50px);
    }
  }
`

const Footer = ({ linkedin, twitter, facebook, copyright }) => (
  <Wrapper>
    <div>
      <ul>
        {linkedin && (
          <li>
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <Icon icon="linkedin" />
            </a>
          </li>
        )}
        {twitter && (
          <li>
            <a
              href={twitter}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <Icon icon="twitter" />
            </a>
          </li>
        )}
        {facebook && (
          <li>
            <a
              href={facebook}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <Icon icon="facebook" />
            </a>
          </li>
        )}
      </ul>
      <span>{copyright}</span>
    </div>
  </Wrapper>
)

Footer.propTypes = {
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  copyright: PropTypes.string.isRequired,
}

export default Footer
