import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  ul {
    margin: 0 auto;
    background-color: var(--grey);
    max-width: var(--maxWidthContent);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;

    li {
      list-style: none;
      width: 160px;
      height: 100px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 30px;

        img {
          max-width: 100px;
          max-height: 100px;
        }
      }
    }
  }
`

const Clients = ({ clients }) => (
  <Wrapper>
    <ul>
      {clients.map((item, index) => {
        return (
          <li key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={item.logo} alt={item.name} />
            </a>
          </li>
        )
      })}
    </ul>
  </Wrapper>
)

Clients.propTypes = {
  clients: PropTypes.array.isRequired,
}

export default Clients
