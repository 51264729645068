import gulhas from "../images/clients/gulhas.svg"
import fpc from "../images/clients/fpc.png"
import motriz from "../images/clients/motriz.png"
import joaoGeada from "../images/clients/joao-geada.png"
import advertio from "../images/clients/advertio.svg"
import celfocus from "../images/clients/celfocus.svg"
import novabase from "../images/clients/novabase.svg"
import csarts from "../images/clients/csarts.svg"
import cmlx from "../images/clients/cm-lisboa.svg"
import isa from "../images/clients/isa.png"
import adisa from "../images/clients/adisa.png"
import wome from "../images/clients/wome.svg"
import shifter from "../images/clients/shifter.svg"
import komuh from "../images/clients/komuh.svg"
import mistaker from "../images/clients/mistaker-maker.png"
import salitre from "../images/clients/salitre.svg"

export const clients = [
  { name: "gulhas", url: "https://www.facebook.com/gulhas.pt/", logo: gulhas },
  {
    name: "Fundação Portuguesa das Telecomunicações",
    url: "https://www.fpc.pt",
    logo: fpc,
  },
  {
    name: "João Geada Creativity",
    url: "https://www.joaogeada.pt",
    logo: joaoGeada,
  },
  { name: "MOTRIZ", url: "http://motrizcapital.com.br", logo: motriz },
  { name: "advert/io", url: "https://advertio.com", logo: advertio },
  { name: "Celfocus", url: "https://www.celfocus.com", logo: celfocus },
  { name: "Novabase", url: "https://www.novabase.pt", logo: novabase },
  {
    name: "Cascais School of Arts & Design",
    url: "https://www.csarts.pt",
    logo: csarts,
  },

  {
    name: "Câmara Municipal de Lisboa",
    url: "https://www.lisboa.pt",
    logo: cmlx,
  },
  {
    name: "Instituto Superior de Agronomia",
    url: "https://www.isa.ulisboa.pt",
    logo: isa,
  },
  {
    name:
      "ADISA – Associação para o Desenvolvimento do Instituto Superior de Agronomia",
    url: "https://www.isa.ulisboa.pt/adisa/apresentacao",
    logo: adisa,
  },
  {
    name: "WOME Mystery Box",
    url: "https://wome.pt",
    logo: wome,
  },
  {
    name: "Mistaker Maker",
    url: "http://mistakermaker.org/manifest",
    logo: mistaker,
  },
  {
    name: "Shifter",
    url: "https://shifter.pt",
    logo: shifter,
  },
  {
    name: "Colectivo Salitre",
    url: "https://instagram.com/salitre.fotografia",
    logo: salitre,
  },
  {
    name: "Komuh",
    url: "https://komuhn.co",
    logo: komuh,
  },
]
