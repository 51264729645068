import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Slideshow from "../atoms/slideshow"
import Button from "../atoms/button"
import { device } from "../ions/breakpoints"

const Wrapper = styled.div`
  position: relative;
  background-color: var(--grey);
  padding: 0 15px 85px 15px;
  z-index: 1;

  button {
    position: absolute;
    left: calc(50% - 18px);
    bottom: 50px;
    transform: translateX(-50%);
    margin-bottom: -8px;
    transition-duration: 0.3s;

    svg {
      width: 36px;
      height: 36px;
      fill: var(--darkGrey);
    }

    &:hover {
      svg {
        fill: var(--red);
      }
    }

    &:last-child {
      transform: translateX(50%);
    }
  }

  > div {
    width: 100%;
    max-width: 800px;

    .slider-wrapper {
      height: initial !important;
    }

    ul.slider {
      height: 100% !important;
    }
  }

  @media ${device.s} {
    padding: 0 30px 85px 30px;
  }

  @media ${device.m} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px 50px 100px 50px;

    button {
      position: initial;
      margin-bottom: 0;
      transform: translateX(0);

      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-left: 15px;
        transform: translateX(0);
      }
    }
  }
`

const Sentences = styled.div`
  background-color: var(--grey);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    line-height: 1.4;

    &.en {
      font-size: 1.5rem;
      font-weight: var(--extraBold);
    }
  }
`

const Testimonials = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const next = () => {
    setCurrentSlide(currentSlide + 1)
  }

  const prev = () => {
    setCurrentSlide(currentSlide - 1)
  }

  return (
    <Wrapper>
      <Button
        className="previous"
        variant="text"
        action={prev}
        icon="arrowBack"
        ariaLabel="Previous Slide"
      />

      <Slideshow slideSelected={currentSlide}>
        {slides.map((slide, index) => (
          <Sentences key={index}>
            <p className="en">{slide.en}</p>
            <p className="pt">{slide.pt}</p>
          </Sentences>
        ))}
      </Slideshow>

      <Button
        className="next"
        variant="text"
        action={next}
        icon="arrowForward"
        ariaLabel="Next Slide"
      />
    </Wrapper>
  )
}

Testimonials.propTypes = {
  slides: PropTypes.array.isRequired,
}

export default Testimonials
