import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import CirclesImage from "../ions/circles-image"
import { device } from "../ions/breakpoints"

const Wrapper = styled.div`
  padding: 50px 15px;
  text-align: center;

  > div {
    position: relative;
    margin: 0 auto;
    max-width: 800px;

    svg {
      display: none;

      circle {
        transition-duration: 0.3s;

        &:hover {
          fill: var(--red);
        }
      }
    }

    h1 {
      position: relative;
      margin: 0 0 30px 0;
      z-index: 1;
    }

    p {
      position: relative;
      z-index: 1;
    }
  }

  @media ${device.s} {
    padding: 50px 30px;
  }

  @media ${device.m} {
    padding: 100px 50px;
    text-align: left;

    > div {
      svg {
        display: initial;
        position: absolute;
        margin: -22px 0 0 -36px;
        width: 12%;
        height: auto;
      }
    }
  }

  @media ${device.l} {
    padding: 100px 50px 160px 50px;

    > div {
      svg {
        position: absolute;
        margin: 20px 0 0 -140px;
        width: 12%;
        height: auto;
      }

      h1 {
        margin: 0 0 30px -100px;
      }
    }
  }
`

const OurWork = ({ id, title, text }) => (
  <Wrapper id={id}>
    <div>
      <CirclesImage />
      <h1>{title}</h1>
      <p>{text}</p>
    </div>
  </Wrapper>
)

OurWork.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default OurWork
