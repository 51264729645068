import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device } from "../ions/breakpoints"
import map from "../../images/map.jpg"

const Wrapper = styled.div`
  position: relative;
  padding: 50px 0;

  > div {
    margin: 0 auto;
    max-width: var(--maxWidthContent);
  }

  @media ${device.m} {
    padding: 100px 0 180px 0;
  }
`

const Form = styled.div`
  padding: 0 15px;
  text-align: center;

  @media ${device.s} {
    padding: 0 30px;
  }

  @media ${device.m} {
    padding: 0 50px;
    text-align: left;
  }

  div {
    p {
      margin-bottom: 30px;
    }

    a {
      border-bottom: 4px solid var(--red);
      font-weight: var(--extraBold);
      color: var(--default);
      text-decoration: none;
      transition-duration: 0.3s;

      &:hover {
        color: var(--blue);
      }
    }

    @media ${device.m} {
      width: calc(50% - 50px);

      p {
        margin-bottom: 50px;
      }
    }
  }
`

const Map = styled.div`
  display: none;
  position: absolute;
  top: 100px;
  right: 0;
  width: 50%;
  height: calc(100% - 100px);
  background-color: var(--red);
  background-image: url(${map});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.m} {
    display: inherit;
  }
`

const Contacts = ({ title, description, email }) => (
  <Wrapper>
    <div>
      <Form>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>

          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </Form>
    </div>
    <Map />
  </Wrapper>
)

Contacts.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  email: PropTypes.string.isRequired,
}

export default Contacts
