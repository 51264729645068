const icons = {
  twitter:
    "M26.169 10.782c0.016 0.227 0.016 0.455 0.016 0.682 0 6.936-5.28 14.928-14.928 14.928-0.011 0-0.025 0-0.039 0-2.979 0-5.752-0.878-8.076-2.39l0.057 0.035c0.356 0.041 0.769 0.065 1.187 0.065 0.028 0 0.056-0 0.085-0l-0.004 0c0.008 0 0.018 0 0.028 0 2.457 0 4.718-0.844 6.507-2.257l-0.022 0.017c-2.286-0.043-4.212-1.539-4.895-3.601l-0.011-0.037c0.297 0.049 0.64 0.078 0.989 0.080h0.002c0.491-0.001 0.967-0.066 1.42-0.187l-0.039 0.009c-2.417-0.502-4.207-2.613-4.207-5.143 0-0.003 0-0.005 0-0.008v0-0.065c0.683 0.388 1.495 0.631 2.361 0.665l0.010 0c-1.418-0.956-2.339-2.556-2.339-4.371 0-0.975 0.266-1.888 0.728-2.671l-0.013 0.024c2.6 3.182 6.445 5.262 10.782 5.489l0.037 0.002c-0.080-0.36-0.127-0.774-0.13-1.198v-0.002c0.004-2.898 2.354-5.246 5.252-5.246 1.508 0 2.868 0.636 3.826 1.654l0.003 0.003c1.255-0.247 2.371-0.687 3.376-1.293l-0.046 0.026c-0.406 1.233-1.222 2.239-2.283 2.878l-0.024 0.013c1.12-0.131 2.141-0.418 3.088-0.84l-0.067 0.027c-0.732 1.057-1.603 1.954-2.6 2.691l-0.031 0.022z",
  facebook:
    "M21.613 17.6l0.711-4.633h-4.446v-3.006c0-1.267 0.621-2.503 2.612-2.503h2.021v-3.945c-1.066-0.181-2.306-0.295-3.569-0.313l-0.018-0c-3.661 0-6.054 2.219-6.054 6.236v3.531h-4.070v4.633h4.070v11.2h5.009v-11.2z",
  linkedin:
    "M8.93 25.599h-5.307v-17.091h5.307zM6.274 6.177c-0.004 0-0.009 0-0.014 0-1.705 0-3.088-1.383-3.088-3.088s1.383-3.088 3.088-3.088c1.7 0 3.079 1.374 3.088 3.072v0.001c0 0.001 0 0.002 0 0.003 0 1.703-1.373 3.085-3.072 3.1h-0.001zM28.794 25.599h-5.296v-8.32c0-1.983-0.040-4.526-2.759-4.526-2.759 0-3.182 2.154-3.182 4.383v8.462h-5.302v-17.091h5.090v2.331h0.074c0.985-1.665 2.772-2.764 4.815-2.764 0.073 0 0.145 0.001 0.217 0.004l-0.010-0c5.371 0 6.359 3.537 6.359 8.131v9.389z",
  translate:
    "M9.612 13.912h-0.020s-0.172 0.836-0.312 1.32l-0.44 1.488h1.516l-0.428-1.488c-0.144-0.484-0.316-1.32-0.316-1.32zM27.84 9.6h-23.68c-0.53 0-0.96 0.43-0.96 0.96v10.88c0 0.53 0.43 0.96 0.96 0.96v0h23.68c0.53 0 0.96-0.43 0.96-0.96v0-10.88c0-0.53-0.43-0.96-0.96-0.96v0zM12.528 19.84h-0.904c-0.216-0-0.399-0.144-0.459-0.341l-0.001-0.003-0.372-1.268h-2.396l-0.364 1.264c-0.059 0.202-0.243 0.347-0.46 0.348h-0.9c-0.001 0-0.001 0-0.002 0-0.265 0-0.48-0.215-0.48-0.48 0-0.056 0.010-0.11 0.027-0.159l-0.001 0.003 2.284-6.72c0.068-0.188 0.245-0.32 0.453-0.32 0.001 0 0.002 0 0.004 0h1.284c0.208 0.001 0.386 0.132 0.455 0.317l0.001 0.003 2.284 6.72c0.017 0.047 0.026 0.1 0.026 0.156 0 0.265-0.215 0.48-0.48 0.48-0.001 0-0.001 0-0.002 0h0zM27.2 20.8h-11.2v-9.6h11.2zM18.080 14.88h4.432c-0.301 0.586-0.647 1.092-1.047 1.548l0.007-0.008c-0.244-0.284-0.485-0.597-0.708-0.923l-0.024-0.037c-0.087-0.129-0.233-0.212-0.398-0.212-0.091 0-0.176 0.025-0.248 0.069l0.002-0.001-0.548 0.328c-0.141 0.085-0.234 0.238-0.234 0.412 0 0.1 0.031 0.193 0.083 0.27l-0.001-0.002c0.313 0.461 0.621 0.86 0.953 1.239l-0.013-0.015c-0.34 0.292-0.715 0.572-1.108 0.824l-0.040 0.024c-0.133 0.086-0.22 0.233-0.22 0.401 0 0.088 0.024 0.17 0.066 0.241l-0.001-0.002 0.316 0.56c0.084 0.145 0.239 0.242 0.417 0.242 0.095 0 0.183-0.027 0.257-0.075l-0.002 0.001c0.543-0.348 1.013-0.701 1.455-1.085l-0.015 0.013c0.413 0.373 0.865 0.723 1.342 1.038l0.042 0.026c0.074 0.049 0.165 0.078 0.263 0.078 0.178 0 0.333-0.097 0.416-0.24l0.001-0.002 0.32-0.56c0.040-0.069 0.064-0.151 0.064-0.239 0-0.167-0.085-0.314-0.214-0.4l-0.002-0.001c-0.408-0.27-0.763-0.546-1.097-0.844l0.009 0.008c0.060-0.068 0.116-0.132 0.172-0.2 0.608-0.705 1.106-1.526 1.453-2.423l0.019-0.057h0.552c0.265 0 0.48-0.215 0.48-0.48v0-0.64c0-0.265-0.215-0.48-0.48-0.48v0h-2.56v-0.64c0-0.265-0.215-0.48-0.48-0.48v0h-0.64c-0.265 0-0.48 0.215-0.48 0.48v0 0.64h-2.56c-0.265 0-0.48 0.215-0.48 0.48v0 0.64c0 0.001-0 0.003-0 0.004 0 0.265 0.215 0.48 0.48 0.48 0 0 0 0 0 0v0z",
  revision:
    "M25.8 13.2h-1.4v-5.020c-0-0.387-0.157-0.736-0.41-0.99v0l-1.979-1.98c-0.253-0.254-0.604-0.41-0.99-0.41-0 0-0 0-0 0h-12.020c-0.773 0-1.4 0.627-1.4 1.4v0 7h-1.4c-0.773 0-1.4 0.627-1.4 1.4v0 1.4c0 0.773 0.627 1.4 1.4 1.4v0 7c0 1.546 1.254 2.8 2.8 2.8v0h14c1.546 0 2.8-1.254 2.8-2.8v0-7c0.773 0 1.4-0.627 1.4-1.4v0-1.4c0-0.773-0.627-1.4-1.4-1.4v0zM9.7 6.9h10.5v1.4c0 0.387 0.313 0.7 0.7 0.7v0h1.4v4.2h-1.52c-0 0-0 0-0 0-0.386 0-0.736 0.157-0.989 0.41v0l-0.58 0.58c-0.253 0.253-0.603 0.41-0.989 0.41-0 0-0 0-0 0h-4.441c-0 0-0 0-0 0-0.386 0-0.736-0.157-0.989-0.41v0l-0.58-0.58c-0.253-0.253-0.603-0.41-0.989-0.41-0 0-0.001 0-0.001 0h-1.52zM23.7 24.4c0 0.387-0.313 0.7-0.7 0.7v0h-14c-0.387 0-0.7-0.313-0.7-0.7v0-7h15.4zM11.1 23.7h9.8c0.387 0 0.7-0.313 0.7-0.7v0-0.7c0-0.387-0.313-0.7-0.7-0.7v0h-9.8c-0.387 0-0.7 0.313-0.7 0.7v0 0.7c0 0.387 0.313 0.7 0.7 0.7v0zM10.75 20.375h1.050c0 0 0.001 0 0.001 0 0.193 0 0.35-0.157 0.35-0.35 0 0 0 0 0 0v0-1.050c0-0.193-0.157-0.35-0.35-0.35h-1.050c-0 0-0.001 0-0.001 0-0.193 0-0.35 0.157-0.35 0.35 0 0 0 0 0 0v0 1.050c0 0 0 0 0 0 0 0.193 0.157 0.35 0.35 0.35 0 0 0.001 0 0.001 0h-0zM20.201 20.375h1.050c0.193-0 0.349-0.157 0.349-0.35 0-0 0-0.001 0-0.001v0-1.050c0-0.193-0.157-0.35-0.35-0.35h-1.050c0 0 0 0 0 0-0.193 0-0.35 0.157-0.35 0.35 0 0 0 0.001 0 0.001v-0 1.050c0 0.193 0.157 0.349 0.35 0.349 0 0 0 0 0 0v0zM13.901 20.375h1.050c0 0 0.001 0 0.001 0 0.193 0 0.35-0.157 0.35-0.35 0 0 0 0 0 0v0-1.050c0-0.193-0.157-0.35-0.35-0.35v0h-1.052c0 0 0 0 0 0-0.193 0-0.35 0.157-0.35 0.35 0 0 0 0.001 0 0.001v-0 1.050c0 0.193 0.157 0.349 0.35 0.349 0 0 0 0 0 0v0zM17.051 20.375h1.050c0 0 0.001 0 0.001 0 0.193 0 0.35-0.157 0.35-0.35 0 0 0 0 0 0v0-1.050c0-0.193-0.157-0.35-0.35-0.35v0h-1.052c0 0 0 0 0 0-0.193 0-0.35 0.157-0.35 0.35 0 0 0 0.001 0 0.001v-0 1.050c0 0.193 0.157 0.349 0.35 0.349 0 0 0 0 0 0v0z",
  creation:
    "M27.018 24.81l-3.169-3.168c0.54-0.784 0.862-1.755 0.862-2.8 0-2.749-2.229-4.978-4.978-4.978s-4.978 2.229-4.978 4.978c0 2.749 2.229 4.978 4.978 4.978 1.044 0 2.012-0.321 2.812-0.87l-0.017 0.011 3.169 3.168c0.113 0.113 0.268 0.182 0.44 0.182s0.327-0.070 0.44-0.182l0.44-0.44c0.112-0.113 0.181-0.268 0.181-0.439s-0.069-0.327-0.181-0.439l0 0zM19.734 21.955c-1.718 0-3.111-1.393-3.111-3.111s1.393-3.111 3.111-3.111c1.718 0 3.111 1.393 3.111 3.111v0c0 0 0 0.001 0 0.001 0 1.718-1.393 3.111-3.111 3.111v0zM6.666 24.445v-16.174h6.222v4.044c0 0.001 0 0.002 0 0.003 0 0.514 0.417 0.93 0.93 0.93 0.001 0 0.002 0 0.003 0h3.239c0.78-0.39 1.699-0.62 2.67-0.625h0.002v-1.090c-0.001-0.516-0.21-0.984-0.548-1.322l-3.266-3.263c-0.337-0.337-0.802-0.547-1.316-0.548h-7.938c-1.031 0.004-1.865 0.839-1.866 1.87v16.175c0 1.031 0.836 1.866 1.866 1.866v0h11.2c0.809-0.002 1.497-0.52 1.752-1.243l0.004-0.013c-0.933-0.019-1.809-0.242-2.592-0.627l0.036 0.016zM14.755 8.422l2.96 2.96h-2.96z",
  arrowBack:
    "M25.333 14.667h-14.893l6.507-6.507c0.52-0.52 0.52-1.373 0-1.893s-1.36-0.52-1.88 0l-8.787 8.787c-0.52 0.52-0.52 1.36 0 1.88l8.787 8.787c0.52 0.52 1.36 0.52 1.88 0s0.52-1.36 0-1.88l-6.507-6.507h14.893c0.733 0 1.333-0.6 1.333-1.333s-0.6-1.333-1.333-1.333z",
  arrowForward:
    "M6.667 17.333h14.893l-6.507 6.507c-0.52 0.52-0.52 1.373 0 1.893s1.36 0.52 1.88 0l8.787-8.787c0.52-0.52 0.52-1.36 0-1.88l-8.773-8.8c-0.52-0.52-1.36-0.52-1.88 0s-0.52 1.36 0 1.88l6.493 6.52h-14.893c-0.733 0-1.333 0.6-1.333 1.333s0.6 1.333 1.333 1.333z",
}

export default icons
