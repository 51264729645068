import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Icon from "./icon"

const ButtonWrapper = styled.button`
  border: 0;
  border-radius: ${props => (props.variant === "text" ? 0 : "999px")};
  background-color: ${props =>
    props.variant === "solid" ? "var(--red)" : "transparent"};
  width: ${props => (props.value ? "min-content" : "36px")};
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.value ? "0 20px" : 0)};
  text-transform: capitalize;
  white-space: nowrap;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.variant === "solid" ? "hsl(0, 100%, 56%)" : "transparent"};
  }

  span {
    position: relative;
    font-size: 0.75rem;
    font-weight: var(--bold, 700);
    color: ${props => (props.variant === "solid" ? "var(--white)" : "")};
    pointer-events: none;
    transition-duration: 0.3s;
  }

  svg {
    width: auto;
    min-width: 20px;
    height: 20px;
    fill: ${props =>
      props.variant === "solid" ? "var(--white)" : "var(--red)"};
    transition: 0.3s;
  }
`

const Button = props => {
  const { variant, className, action, icon, value, ariaLabel } = props

  return (
    <ButtonWrapper
      variant={variant}
      className={className}
      value={value}
      aria-label={ariaLabel}
      onClick={action}
    >
      {icon ? <Icon icon={icon} /> : null}
      {value && <span>{value}</span>}
    </ButtonWrapper>
  )
}

Button.defaultProps = {
  loading: false,
  variant: "solid",
  action: () => {},
}

Button.propTypes = {
  variant: PropTypes.oneOf(["solid", "text"]),
  className: PropTypes.string,
  action: PropTypes.func.isRequired,
  icon: PropTypes.string,
  value: PropTypes.node,
  ariaLabel: PropTypes.string,
}

export default Button
